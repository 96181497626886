var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"保存","back-button-text":"上一项","next-button-text":"下一项"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"填写公司信息","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" 填写公司信息 ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"公司名称","label-for":"company-name"}},[_c('validation-provider',{attrs:{"name":"公司名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-name","state":errors.length > 0 ? false:null},model:{value:(_vm.applicationData.companyName),callback:function ($$v) {_vm.$set(_vm.applicationData, "companyName", $$v)},expression:"applicationData.companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"税号","label-for":"tax-number"}},[_c('validation-provider',{attrs:{"name":"税号","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tax-number","state":errors.length > 0 ? false:null},model:{value:(_vm.applicationData.taxNumber),callback:function ($$v) {_vm.$set(_vm.applicationData, "taxNumber", $$v)},expression:"applicationData.taxNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"开户银行（支行）","label-for":"opening-bank"}},[_c('validation-provider',{attrs:{"name":"开户银行（支行）","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"opening-bank","state":errors.length > 0 ? false:null},model:{value:(_vm.applicationData.openingBank),callback:function ($$v) {_vm.$set(_vm.applicationData, "openingBank", $$v)},expression:"applicationData.openingBank"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"银行账号","label-for":"bank-account"}},[_c('validation-provider',{attrs:{"name":"银行账号","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank-account","state":errors.length > 0 ? false:null},model:{value:(_vm.applicationData.bankAccount),callback:function ($$v) {_vm.$set(_vm.applicationData, "bankAccount", $$v)},expression:"applicationData.bankAccount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"注册地址","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"注册地址","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false:null},model:{value:(_vm.applicationData.address),callback:function ($$v) {_vm.$set(_vm.applicationData, "address", $$v)},expression:"applicationData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"座机","label-for":"telephone"}},[_c('validation-provider',{attrs:{"name":"座机","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"telephone","state":errors.length > 0 ? false:null},model:{value:(_vm.applicationData.telephone),callback:function ($$v) {_vm.$set(_vm.applicationData, "telephone", $$v)},expression:"applicationData.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"填写联系人信息","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" 填写联系人信息 ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"姓","label-for":"last-name"}},[_c('validation-provider',{attrs:{"name":"姓","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","state":errors.length > 0 ? false:null},model:{value:(_vm.applicationData.lastName),callback:function ($$v) {_vm.$set(_vm.applicationData, "lastName", $$v)},expression:"applicationData.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"名","label-for":"first-name"}},[_c('validation-provider',{attrs:{"name":"名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","state":errors.length > 0 ? false:null},model:{value:(_vm.applicationData.firstName),callback:function ($$v) {_vm.$set(_vm.applicationData, "firstName", $$v)},expression:"applicationData.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"职位","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"职位","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false:null},model:{value:(_vm.applicationData.title),callback:function ($$v) {_vm.$set(_vm.applicationData, "title", $$v)},expression:"applicationData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"性别","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"性别","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.genderOptions,"reduce":function (val) { return val.value; },"clearable":true,"input-id":"gender"},model:{value:(_vm.applicationData.gender),callback:function ($$v) {_vm.$set(_vm.applicationData, "gender", $$v)},expression:"applicationData.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"电话","label-for":"contact-phone"}},[_c('validation-provider',{attrs:{"name":"电话","rules":"required|digits:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contact-phone","state":errors.length > 0 ? false:null},model:{value:(_vm.applicationData.contactPhone),callback:function ($$v) {_vm.$set(_vm.applicationData, "contactPhone", $$v)},expression:"applicationData.contactPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"contact-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contact-email","state":errors.length > 0 ? false:null},model:{value:(_vm.applicationData.contactEmail),callback:function ($$v) {_vm.$set(_vm.applicationData, "contactEmail", $$v)},expression:"applicationData.contactEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"签署合同","before-change":_vm.validationFormAddress}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" 签署合同 ")]),_c('small',{staticClass:"text-muted"},[_vm._v("请下载合同后盖章并回传扫描件")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1 mb-1",attrs:{"variant":"primary","disabled":_vm.isDownloading},on:{"click":_vm.genContract}},[_vm._v("下载合同")])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"上传已签署合同","label-for":"upload"}},[_c('b-form-file',{attrs:{"accept":".pdf","placeholder":"请选择或拖拽文件至此（仅支持pdf格式）..."},model:{value:(_vm.signedContract),callback:function ($$v) {_vm.signedContract=$$v},expression:"signedContract"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }