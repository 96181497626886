<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="保存"
      back-button-text="上一项"
      next-button-text="下一项"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <tab-content
        title="填写公司信息"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                填写公司信息
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="公司名称"
                label-for="company-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="公司名称"
                  rules="required"
                >
                  <b-form-input
                    id="company-name"
                    v-model="applicationData.companyName"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="税号"
                label-for="tax-number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="税号"
                  rules="required"
                >
                  <b-form-input
                    id="tax-number"
                    v-model="applicationData.taxNumber"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="开户银行（支行）"
                label-for="opening-bank"
              >
                <validation-provider
                  #default="{ errors }"
                  name="开户银行（支行）"
                  rules="required"
                >
                  <b-form-input
                    id="opening-bank"
                    v-model="applicationData.openingBank"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="银行账号"
                label-for="bank-account"
              >
                <validation-provider
                  #default="{ errors }"
                  name="银行账号"
                  rules="required"
                >
                  <b-form-input
                    id="bank-account"
                    v-model="applicationData.bankAccount"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="注册地址"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="注册地址"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="applicationData.address"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="座机"
                label-for="telephone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="座机"
                  rules="required|integer"
                >
                  <b-form-input
                    id="telephone"
                    v-model="applicationData.telephone"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content
        title="填写联系人信息"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                填写联系人信息
              </h5>
            </b-col>
            
            <b-col md="6">
              <b-form-group
                label="姓"
                label-for="last-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="姓"
                  rules="required"
                >
                  <b-form-input
                    id="last-name"
                    v-model="applicationData.lastName"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
           
            <b-col md="6">
              <b-form-group
                label="名"
                label-for="first-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="名"
                  rules="required"
                >
                  <b-form-input
                    id="first-name"
                    v-model="applicationData.firstName"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


            <b-col md="6">
              <b-form-group
                label="职位"
                label-for="title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="职位"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="applicationData.title"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="性别"
                label-for="gender"
              >
                <validation-provider
                  #default="{ errors }"
                  name="性别"
                  rules="required"
                >
                <v-select
                v-model="applicationData.gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="genderOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="gender"
              />
                 
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="电话"
                label-for="contact-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="电话"
                  rules="required|digits:11"
                >
                  <b-form-input
                    id="contact-phone"
                    v-model="applicationData.contactPhone"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Email"
                label-for="contact-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="contact-email"
                    v-model="applicationData.contactEmail"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content
        title="签署合同"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                签署合同
              </h5>
              <small class="text-muted">请下载合同后盖章并回传扫描件</small>
            </b-col>
            <b-col md="12">
              <b-button
                variant="primary"
                class="mr-1 mb-1"
                @click="genContract"
                :disabled="isDownloading"
                >下载合同</b-button>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="上传已签署合同"
                label-for="upload"
              >
              <b-form-file
                  accept=".pdf"
                  v-model="signedContract"
                  placeholder="请选择或拖拽文件至此（仅支持pdf格式）..."
                />
              </b-form-group>
            </b-col>

          </b-row>
        </validation-observer>
      </tab-content>

    </form-wizard>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BAlert
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    BFormFile,
    BAlert
  },
  data() {
    return {
      genderOptions:[
        {label:'男',value:'male'},
        {label:'女',value:"female"},
      ],
      isDownloading: false,
      signedContract: null,
      applicationData:{
        "companyName": "",
        "taxNumber":"",
        "openingBank":"",
        "bankAccount":"",
        "address":"",
        "telephone":"",
        "lastName":"",
        "firstName":"",
        "gender":"",
        "title":"",
        "contactPhone":"",
        "contactEmail":"",
      },
      required,
      email,
      codeIcon,
    }
  },
  methods: {
    formSubmitted() {
      if (!this.signedContract) {
        this.$toast.error('请上传已签署合同') 
        return
      }else{
        var formData = new FormData()
        formData.append('contract_file', this.signedContract)
        formData.append('address', this.applicationData.address)
        formData.append('bank_account', this.applicationData.bankAccount)
        formData.append('company_name', this.applicationData.companyName)
        formData.append('telephone', this.applicationData.telephone)
        formData.append('tax_number', this.applicationData.taxNumber)
        formData.append('opening_bank', this.applicationData.openingBank)
        formData.append('contact_last_name', this.applicationData.lastName)
        formData.append('contact_first_name', this.applicationData.firstName)
        formData.append('contact_phone', this.applicationData.contactPhone)
        formData.append('contact_email', this.applicationData.contactEmail)
        formData.append('contact_title',this.applicationData.title)
        formData.append('contact_gender',this.applicationData.gender)
        this.$axios.post('/partner/companies/apply_contract',formData).then(resp =>{
          if(resp.data.status == "ok"){
            this.$router.push({ name: 'application-pending-partner' })
          }else{
            this.$router.push({ name: 'redirect' })
          }
        })
      }
    },
    genContract() {
      this.isDownloading = true
      this.$axios.post('/partner/companies/gen_contract',this.applicationData,{responseType:"blob"}).then(resp =>{
        this.isDownloading = false
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', 'CONTRACT.pdf')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
