<template>
  <b-row>
    <b-alert
      v-if="userStatus != 'rejected'"
      variant="success"
      show
    >
      <div class="alert-body">
        <span>您已正式被邀约入库，请认真填写以下信息，以下信息将被填写至合同中。</span>
      </div>
    </b-alert>

    <b-alert
      v-if="userStatus == 'rejected'"
      variant="warning"
      show
    >
      <div class="alert-body">
        <span>您的入库申请被驳回，请重新填写以下信息，以下信息将被填写至合同中。</span>
      </div>
    </b-alert>
      <b-col cols="12">

      <form-wizard-number />

    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol,BAlert } from 'bootstrap-vue'
import FormWizardNumber from './FormWizardNumber.vue'

export default {
  components: {
    BRow,
    BCol,
    FormWizardNumber,
    BAlert
  },
  data(){
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  computed:{
      userStatus(){
        var userStatus = JSON.parse(localStorage.getItem('userData')).inviteStatus
        if (userStatus == 'rejected'){
          return 'rejected'
        }
      }
    
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
